import {PortalRequest as request} from '@/utils/request'

// 解决方案类别
export const getCategoryList = (type) => {
    return request({
        url: `/category/${type}/list`,
        method: "get",
    });
};

// 分页查看解决方案
export const getSolutionList = (params, data) => {
    return request({
        url: "/solution/h5-page-list",
        method: "post",
        params: params,
        data,
    });
};

// 获取解决方案信息
export const getSolutionInfo = (id) => {
    return request({
        url: `/solution/${id}/detail`,
        method: "get",
    });
};