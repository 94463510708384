<template>
  <div class="product-footer-box">
    <van-button class="btn-red" @click="demandMove">我有国产迁移需求</van-button>
    <van-button class="footer-btn-gray" @click="applyDev">免费申请开发板</van-button>
  </div>
</template>

<script>
export default {
  methods: {
    applyDev() {
        this.$router.push('/applyDev')
    },
    demandMove(){
      localStorage.removeItem('orderVal')
      this.$router.push('/demandMove')
    }
  },
};
</script>

<style lang="scss" scoped>
.product-footer-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8;
  background: #fff;
  padding: 16px 16px 24px;
  display: flex;
  justify-content: space-between;
  > button {
    height: 44px;
    border: none;
    width: 49%;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    font-size: 14px;
  }
  .footer-btn-gray {
    background: #f7f8fa;
    border: 2px solid #ffffff;
  }
}
</style>