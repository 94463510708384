<template>
  <div class="solution-search">
    <div class="solution-search-head">
      <div class="solution-search-banner">
        <strong>方案中心</strong>
        <p>提供<span>鸿蒙化</span>解决方案升级改造服务</p>
      </div>
      <div class="solution-search-head-lable">
        <div>
          <span>
            信息技术应用创新与发展是目前的一项国家战略，也是当今形势下国家经济发展的新动能。发展国产系统是为了解决自主可掌控、可研究、可发展、可生产的本质安全问题。鸿联联创平台致力于为您提供鸿蒙解决方案升级改造服务。
          </span>
          <div>
            <div>
              <img src="@/assets/img/4997@2x.png" alt="开源鸿蒙">
              <p>开源鸿蒙</p>
            </div>
            <div>
              <img src="@/assets/img/4998@2x.png" alt="自主可控">
              <p>自主可控</p>
            </div>
            <div>
              <img src="@/assets/img/4999@2x.png" alt="分布式处理">
              <p>分布式处理</p>
            </div>
            <div>
              <img src="@/assets/img/5000@2x.png" alt="内核级安全">
              <p>内核级安全</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="solution-search-list-box">
      <p>
        <span :class="{'active':solutionTypeIndex == 1}" @click="solutionTypeTag(1)">鸿蒙方案</span>
        <span :class="{'active':solutionTypeIndex == 2}" @click="solutionTypeTag(2)">数字化转型方案</span>
      </p>
      <div class="solution-search-filter">
        <div class="solution-search-tabs">
          <template v-if="isFilter">
            <van-tabs title-active-color="#EA0B06" line-height="0" v-model="solutionTabsIndex" @click="solutionTabs">
              <van-tab v-for="(el,index) in solutionSortTwoList" :key="index" :name="el.id" :title="el.name"/>
            </van-tabs>
          </template>
          <p v-else>请选择</p>
        </div>
        <van-dropdown-menu :overlay="false" z-index="20">
          <van-dropdown-item ref="filter" @open="openFilter" @close="closeFilter">
            <template slot="title">
              <span class="solution-search-filter-icon"><van-icon name="play"/></span>
            </template>
            <div class="solution-search-filter-box">
              <div class="solution-search-filter-list">
                <span v-for="(el,index) in solutionSortTwoList" :key="el.id" :class="{'active':el.id == categoryId}"
                      @click="solutionFilter(el.id,index)">{{ el.name }}</span>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad"
                v-if="solutionList.length>0">
        <div class="solution-search-list">
          <div v-for="(el,index) in solutionList" :key="index" :data-id="el.id" @click="solutionInfo(el.id,el.hotType)">
            <div class="solution-search-list-img">
              <span v-if="el.marketing == 2" class="tuijian"><img src="@/assets/img/tjicon.png" alt="推荐"></span>
              <span v-if="el.marketing == 3" class="huore"><img src="@/assets/img/hricon.png" alt="火热"></span>
              <v-img :attachment-id="el.solutionImg " :default-img="require('@/assets/img/kctp.png')"/>
            </div>
            <div class="solution-search-list-info">
              <strong>{{ el.name }}</strong>
              <p v-html="el.solutionDiscription"></p>
            </div>
          </div>
        </div>
      </van-list>

      <van-empty
        v-if="emptybool"
        class="custom-image"
        :image="nonecontent"
        description="暂无内容"
      />

    </div>
    <!-- <div class="solution-consult" @click="solutionConsult">
      <img src="@/assets/img/wyzx.png" alt="wyzx">
    </div> -->
    <product-footer/>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { getSolutionList, getCategoryList } from '@/api/solution'
import VImg from '@/components/VImg'
import productFooter from '../components/productFooter.vue'
import nonecon from '@/assets/img/nonecontent1.png'
import share from '../components/share.vue'

export default {
  components: {
    VImg,
    productFooter,
    share
  },
  data () {
    return {
      solutionSortTwoList: [{
        id: '',
        name: '全部'
      }],
      page: {
        current: 1,
        size: 10,
        pageSize: 1,
      },
      nonecontent: nonecon,
      categoryId: '',
      loading: false,
      finished: false,
      solutionList: [],
      solutionTabsIndex: 0,
      marketingId: '',
      isFilter: true,
      emptybool: false,
      shareInfo: {
        title: '寻｜解决方案',
        desc: '信息技术应用创新与发展是目前的一项国家战略，也是当今形势下国家经济发展的新动能。发展国产系统是为了解决自主可掌控、可研究、可发展、可生产的本质安全问题。鸿联联创平台致力于为您提供鸿蒙解决方案升级改造服务。',
        img: `${process.env.VUE_APP_OBS_PREFIX}/1553280012038230017`
      }
    }
  },

  computed: {

    solutionTypeIndex: {
      get: function () {
        if (this.$route.query.index) {
          return this.$route.query.index
        } else {
          return 1
        }
      },

      set: function () {

      }
    }

  },

  mounted () {
    document.title = '寻｜解决方案'
    if (this.$route.query.id) {
      this.categoryId = this.$route.query.id
    }

    this.loadGetSolutionCategory()
    this.loadGetSolutionList()
    if (this.$route.query.code) {
      this.wechatUtil.saveWechatInfo(this.$route.query.code, localStorage.getItem('openId'))
    }
  },

  watch: {
    solutionTypeIndex () {
      this.page.current = 1
      this.solutionList = []
      this.loadGetSolutionList()
    },
  },

  methods: {

    applyDev () {
      this.$router.push('/applyDev')
    },
    onLoad () {
      if (this.solutionList == '') {
        return
      }
      if (this.page.pageSize == this.page.current) {
        this.finished = true
        return
      }
      this.page.current++
      this.loading = true
      this.loadGetSolutionList()
    },
    // 类别列表
    loadGetSolutionCategory () {
      getCategoryList('1').then((res) => {
        let data = res
        data.forEach((element) => {
          element.children.forEach((item) => {
            this.solutionSortTwoList.push(item)
          })
        })
        setTimeout(() => {
          this.solutionTabsIndex = this.categoryId
        }, 200)
      })
    },
    solutionTypeTag (index) {
      this.$router.push(`/solution/searchList/?index=${index}`)
    },
    // 解决方案列表
    loadGetSolutionList () {
      let {
        page,
        categoryId,
        solutionTypeIndex,
        marketingId
      } = this
      let data = {
        status: '',
        marketing: marketingId,
        tag: '',
        category: categoryId,
        orderField: 'marketing'
      }
      if (solutionTypeIndex == 1) {
        data.notM0 = 1
      } else if (solutionTypeIndex == 2) {
        data.notHm = 1
      }
      getSolutionList(page, data).then((res) => {
        this.loading = false
        this.solutionList = [...this.solutionList, ...res.records]

        this.page.pageSize = res.pages
        // if(res.pages <= res.current){
        //   this.finished = true;
        //   return
        // }
        //this.loading = true;
        this.finished = false
        if (this.solutionList.length == 0) {
          this.emptybool = true
        } else {
          this.emptybool = false
        }
      })
    },
    solutionTabs (id, val) {
      this.finished = false
      this.categoryId = id
      this.page.current = 1
      this.solutionList = []
      this.loadGetSolutionList()
    },
    solutionFilter (id, index) {
      this.categoryId = id
      this.solutionTabsIndex = id
      this.page.current = 1
      this.solutionList = []
      this.$refs.filter.toggle()
      this.loadGetSolutionList()
    },
    openFilter () {
      this.isFilter = false
    },
    closeFilter () {
      this.isFilter = true
    },
    solutionInfo (id, type) {
      /*  this.$router.push({
         path: "/solution/info",
         query: { id },
       }); */

      // type  1 常规方案，2 3 鸿蒙方案， 4 小红书方案 5 普通方案 6数字方案
      // 1 ， 2 3，4 5，6

      if (type == 2 || type == 3) {
        this.$router.push({
          path: '/solution/info',
          query: { id },
        })
      } else if (type == 6) {
        this.$router.push({
          path: '/solution/digitizing',
          query: { id },
        })
      } else if (type == 1) {
        this.$router.push({
          path: '/solution/internet_things',
          query: { id },
        })
      } else if (type == 4 || type == 5) {
        this.$router.push({
          path: '/solution/ordinary_program',
          query: { id },
        })
      }
    },
    solutionConsult () {
      this.$router.push('/wordForm')
    }
  }
}
</script>

<style lang="scss" scoped>
.solution-search {
  min-height: 100%;
  background: #F6F6F6;

  .custom-image {
    ::v-deep {
      .van-empty__image {
        width: 114px;
        height: 74.09px;
      }

      .van-empty__description {
        font-size: 14px;
        font-weight: 500;
        line-height: 46px;
        color: #999999;
      }
    }
  }

  .solution-search-head {
    .solution-search-banner {
      padding: 38px 24px;
      background: url(../../assets/img/5018@2x.png) no-repeat;
      background-size: 100% 100%;
      height: 210px;

      > strong {
        font-size: 20px;
        line-height: 28px;
        color: #333333;
        display: block;
        margin-bottom: 10px;
      }

      > p {
        font-size: 12px;
        line-height: 18px;
        color: #333333;
        margin-bottom: 0;

        > span {
          color: #EA0B06;
        }
      }
    }

    .solution-search-head-lable {
      padding: 0 16px;
      position: relative;
      z-index: 2;
      margin-top: -50px;

      > div {
        background: url(../../assets/img/5020@2xbg.png) no-repeat;
        background-size: 100% 100%;
        padding: 24px 12px;
        border-radius: 4px;

        > span {
          display: block;
          font-size: 12px;
          line-height: 18px;
          color: #666666;
          margin-bottom: 24px;
        }

        > div {
          display: flex;
          justify-content: space-between;

          > div {
            > img {
              display: block;
              margin: 0 auto 6px;
              width: 28px;
            }

            > p {
              font-size: 12px;
              line-height: 17px;
              color: #333333;
              text-align: center;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .solution-search-list-box {
    > p {
      border-bottom: 1px solid #E2E2E2;
      padding: 0 16px;
      display: flex;
      justify-content: center;

      > span {
        height: 48px;
        line-height: 48px;
        font-size: 14px;
        position: relative;
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 2px;
          width: 50%;
          left: 50%;
          background: transparent;
          transform: translateX(-50%);
        }

        &.active {
          color: #EA0B06;

          &::after {
            background: #EA0B06;
          }
        }
      }
    }

    .solution-search-filter {
      display: flex;
      height: 48px;
      align-items: center;
      justify-content: space-between;
      margin: 10px 16px 14px;
      background: #fff;

      > p {
        width: calc(100% - 48px);
        margin-bottom: 0;
        overflow-x: auto;
        white-space: nowrap;
        padding: 0 16px;

        &::-webkit-scrollbar {
          display: none;
        }

        > span {
          display: inline-block;
          line-height: 48px;
          font-size: 14px;
          font-weight: 400;
          color: #333;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            color: #EA0B06;
          }
        }
      }

      .solution-search-tabs {
        width: calc(100% - 48px);

        > p {
          padding: 0 16px;
          font-size: 14px;
          color: #666;
          line-height: 48px;
        }
      }

      .solution-search-filter-box {
        padding: 16px 36px;

        .solution-search-filter-list {
          display: flex;
          flex-wrap: wrap;

          > span {
            width: 32%;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #333;
            margin-right: 2%;
            margin-bottom: 24px;

            &:nth-child(3n) {
              margin-right: 0;
            }

            &.active {
              color: #EA0B06;
            }
          }
        }

        .solution-search-filter-btn {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;

          > button {
            width: 140px;
            height: 36px;
            line-height: 36px;
          }
        }
      }
    }

    .solution-search-list {
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      > div {
        width: 167px;
        background: #fff;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        // overflow: hidden;
        margin-bottom: 10px;

        .solution-search-list-img {
          position: relative;
          height: 94px;

          > img {
            display: block;
            width: 100%;
            height: 100%;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }

          > span {
            position: absolute;
            display: block;

            > img {
              display: block;
              width: 100%;
            }

            &.huore {
              width: 16px;
              top: 5px;
              left: 5px;
            }

            &.tuijian {
              width: 32px;
              left: 10px;
              top: -4px;
            }
          }
        }

        .solution-search-list-info {
          padding: 8px 12px;

          > strong {
            display: block;
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 8px;
          }

          > p {
            font-size: 12px;
            line-height: 18px;
            color: #666666;
            margin-bottom: 0;
            height: 36px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }

  .solution-consult {
    position: fixed;
    right: 8px;
    bottom: 100px;
    z-index: 10;
    width: 36px;

    > img {
      width: 36px;
    }
  }
}

.product-footer-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8;
  background: #fff;
  padding: 16px 16px 24px;
  display: flex;
  justify-content: space-between;

  > button {
    height: 44px;
    border: none;
    width: 49%;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    font-size: 14px;
  }

  .footer-btn-gray {
    background: #f7f8fa;
    border: 2px solid #ffffff;
  }
}
</style>
<style>
.solution-search-filter .van-dropdown-menu__bar {
  background-color: transparent;
  height: auto;
  box-shadow: none;
}

.solution-search-filter .van-dropdown-menu__title {
  padding: 0;
  line-height: 48px;
}

.solution-search-filter .van-dropdown-menu__title::after {
  display: none;
}

.solution-search-filter-icon {
  display: block;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.07);
}

.solution-search-filter .van-dropdown-item__content {
  left: 16px;
  right: 16px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
  width: auto;
}

.solution-search-filter-icon > i {
  transition: all .3s;
  transform: rotate(90deg);
}

.solution-search-filter .van-ellipsis {
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.07);
}

.solution-search-filter .van-dropdown-item {
  left: 0;
  right: 0;
}

.van-dropdown-menu__title--active .solution-search-filter-icon > i {
  transform: rotate(270deg);
}
</style>
